export default function getState() {
    var states = [
        'vic',
        'nsw',
        'nt',
        'qld',
        'tas',
        'wa',
        'sa',
        'act'
    ];

    var windowUrl = window.location.href;
    var stateHolders = document.querySelectorAll('.your-state');

    for(var i = 0; i < states.length; i++){
        if(windowUrl.toLowerCase().indexOf('='+states[i]) > -1){
            stateHolders.forEach(function(el, index){
                el.textContent = states[i].toUpperCase();
            });
        }
    }
}
