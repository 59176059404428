import contentLoaded from './vendor/contentloaded.min.js'
import './vendor/jquery.viewportchecker.min.js'
import './vendor/micromodal.js'
import images from './images.js'
import polyfill from './polyfills.js'
import smoothScroll from './smooth-scroll.js'
import videos from './video-embeds.js'
import viewAnimation from './view-animation.js'
import kkThankYou from './thank-you.js';
import getState from './state.js';
import getDayTime from './date.js';
import popups from './popups.js';
import exitIntent from './exitintent.js';
import sourceUrl from './sourceurl.js';

contentLoaded();

window.contentLoaded(window, function (e) {
	polyfill();
	popups();
	kkThankYou();
	images();
	smoothScroll();
	videos();
	viewAnimation();
	getState();
	getDayTime();
	exitIntent();
	sourceUrl();
});
