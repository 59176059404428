export default function exitIntent() {
    var exitHappened = 0;
    setTimeout(function(){
        document.addEventListener("mouseout", function(evt){
            if ((evt.toElement === null && evt.relatedTarget === null || (typeof evt.toElement == "undefined" && evt.relatedTarget === null)) && exitHappened < 1) {
                
                if(!document.querySelector('.modal.is-open')){
                    MicroModal.show('modal-2',{
                        onShow: window.mmOnOpen, 
                        onClose: window.mmOnClose
                    });
                    exitHappened = 1;
                }
            }
        });
    }, 4000);
}
