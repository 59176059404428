export default function videos() {

    var videoButtons = document.querySelectorAll('.inline-video-trigger');
    var videoModal = document.querySelector('#videomodal');

    if(!videoModal){
        return;
    }

    var videoModalContent = document.querySelector('#videomodal .video-container');

    videoButtons.forEach(function(el, index){
        el.addEventListener('click', function(){
            if(el.dataset.videoId){
                var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + el.dataset.videoId + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
                
                var existingIframe = videoModal.querySelector('iframe');

                if(existingIframe){
                    existingIframe.remove();
                }

                videoModalContent.innerHTML = iframeHTML;

                MicroModal.show('videomodal', {
                    onShow: window.mmOnOpen, 
                    onClose: window.mmOnClose
                });
            }
        });
    });
}
