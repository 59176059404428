export default function getDayTime() {
    var today = new Date();
    var fm = [];

    // 30 days in the past
    today.setDate(today.getDate() - 30);

    var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    var thisMonth = months[today.getMonth()];

    fm[0] = today.getDate();
    fm[1] = thisMonth;
    fm[2] = today.getFullYear();

    var dateHolder = document.querySelector('.article-time');

    if(dateHolder){
        dateHolder.textContent = fm[0]+' '+fm[1]+', '+fm[2];
    }

    var yearHolder = document.querySelectorAll('.year-holder');
    if(yearHolder.length){  
        yearHolder.forEach(function(el, index){
            el.textContent = today.getFullYear();
        });
    }
}
