export default function popups(){
	var popups = document.querySelectorAll('.modal');
	popups.forEach(function(el, index){
		el.classList.add('ready');
	});	

	window.mmOnOpen = function(modal){
		document.documentElement.classList.add('modal-open')
	}

	window.mmOnClose = function(modal){
		document.documentElement.classList.remove('modal-open');

		var vmContent = document.querySelector('#videomodal .video-container');

		// stop video
		if(vmContent){
			vmContent.innerHTML = '';
		}
	}
	
	MicroModal.init({
		onShow: window.mmOnOpen, 
		onClose: window.mmOnClose
	});
}